<template>
  <div></div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "management",
  computed: {
    ...mapState({
      storeMenu: (state) => state.backendMenu.menu,
    }),
  },
  mounted() {
    let _this = this;

    function defaultActiveInit(data) {
      data.some((val) => {
        if (val.children && val.children.length > 0) {
          if (val.children[0].menuLinkUrl) {
            _this.$router.push({ name: val.children[0].menuLinkUrl });
            return true;
          } else {
            defaultActiveInit(val.children);
          }
        }
      });
    }

    if (_this.storeMenu && _this.storeMenu.length > 0) {
      defaultActiveInit(_this.storeMenu);
    } else {
      let sstIn = setInterval(() => {
        if (_this.storeMenu && _this.storeMenu.length > 0) {
          clearInterval(sstIn);
          defaultActiveInit(_this.storeMenu);
        }
      }, 300);
    }
  },
};
</script>
